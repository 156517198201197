import React from "react";
import WebGLLoaderChapter1 from "../../../webgl/WebGLLoaderChapter1";
import WebGLLoaderChapter2 from "../../../webgl/WebGLLoaderChapter2";
import WebGLLoaderChapter3 from "../../../webgl/WebGLLoaderChapter3";
import WebGLLoaderChapter4 from "../../../webgl/WebGLLoaderChapter4";

const componentMapping = {
  SoftSkills: {
    Chapter1: WebGLLoaderChapter1,
    Chapter2: WebGLLoaderChapter2,
    Chapter3: WebGLLoaderChapter3,
    Chapter4: WebGLLoaderChapter4,
  },
};

const PromotionalVideo = ({ courseName, chapterName }) => {
  const formattedCourseName = String(courseName).replace("-", "");
  const formattedChapterName = String(chapterName).replace("_", "");

  const SelectedComponent =
    componentMapping[formattedCourseName]?.[formattedChapterName] || null;

  return (
    <div>
      {SelectedComponent ? (
        <SelectedComponent
          courseName={formattedCourseName}
          chapterName={formattedChapterName}
        />
      ) : (
        <p>Component not found</p>
      )}
    </div>
  );
};

export default PromotionalVideo;
