import React from "react";
import "./duration.scss";

const Duration = ({ firstLoginTime }) => {
  return (
    <div className="duration">
      <p>
        You enrolled in this
        <br />
        <span>
          course on <b>{firstLoginTime}</b>
        </span>
      </p>
    </div>
  );
};

export default Duration;
