// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading {
  display: flex;
  align-items: center;
  gap: 10px;
}
.heading h1 {
  color: #012970;
  font-size: 26px;
  text-transform: uppercase;
}
.heading .divider {
  border: none;
  height: 20px;
  border-right: 2px solid #000;
}
.heading h5 {
  text-transform: capitalize;
}`, "",{"version":3,"sources":["webpack://./src/components/chapter/heading/heading.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACE,cAAA;EACA,eAAA;EACA,yBAAA;AACN;AAEI;EACE,YAAA;EACA,YAAA;EACA,4BAAA;AAAN;AAGI;EACE,0BAAA;AADN","sourcesContent":[".heading {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    h1 {\n      color: #012970;\n      font-size: 26px;\n      text-transform: uppercase;\n    }\n\n    .divider {\n      border: none;\n      height: 20px;\n      border-right: 2px solid #000;\n    }\n\n    h5 {\n      text-transform: capitalize;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
