import React from "react";
import "./totalcourses.scss";
import { FiBook } from "react-icons/fi";

const TotalCourses = ({ total }) => {
  return (
    <div className="total-courses">
      <FiBook className="icon" />
      <h3>Total Courses</h3>
      <p>{`${total}`}</p>
    </div>
  );
};

export default TotalCourses;
