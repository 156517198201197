import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const AssignmentBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={360}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="chapter"
          tickMargin={10}
          label={{
            value: "",
            position: "insideBottom",
            offset: -10,
            textAnchor: "middle",
          }}
        />
        <YAxis
          label={{
            value: "Assignment (%)",
            angle: -90,
            position: "insideLeft",
            offset: 0,
            style: {
              textAnchor: "middle",
            },
          }}
          domain={[0, 100]}
          ticks={[0, 20, 40, 60, 80, 100]}
        />
        <Tooltip
          content={({ payload, label, active }) => {
            if (active && payload && payload.length) {
              const { value } = payload[0];
              return (
                <div
                  style={{
                    backgroundColor: "#000",
                    color: "#fff",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: "20px",
                    border: "1px solid #ccc",
                    padding: "15px",
                  }}
                >
                  <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
                  <p style={{ margin: 0 }}>{`Assignment: ${value}%`}</p>
                </div>
              );
            }
            return null;
          }}
        />
        <Bar
          dataKey="assignmentPercentage"
          fill="#e2d0b2"
          stroke="#4a4a4a"
          strokeWidth={1}
          shape={<CustomBar />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        style={{
          filter: "drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.6))",
        }}
      />
      <polygon
        points={`${x},${y} ${x + width},${y} ${x + width - 8},${y - 8} ${
          x + 8
        },${y - 8}`}
        fill={fill}
        fillOpacity="0.7"
      />
      <polygon
        points={`${x + width},${y} ${x + width - 8},${y - 8} ${x + width - 8},${
          y + height - 8
        } ${x + width},${y + height}`}
        fill={fill}
        fillOpacity="0.5"
      />
    </g>
  );
};

export default AssignmentBarChart;
