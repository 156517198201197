import React from "react";
import "./headsetorientationchart.scss";
import { ResponsiveRadar } from "@nivo/radar";

const HeadsetOrientationChart = ({ data }) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveRadar
        data={data}
        keys={["value"]}
        indexBy="position"
        maxValue="auto"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        curve="linearClosed"
        borderWidth={2}
        borderColor="#5CB8FF"
        gridLevels={5}
        gridShape="circular"
        gridLabelOffset={36}
        enableDots={true}
        dotSize={8}
        dotColor={{ theme: "background" }}
        dotBorderWidth={2}
        dotBorderColor="#5CB8FF"
        enableDotLabel={true}
        dotLabel="value"
        dotLabelYOffset={-12}
        colors="#5CB8FF"
        fillOpacity={0.25}
        blendMode="multiply"
        animate={true}
        motionConfig="wobbly"
        isInteractive={true}
      />
    </div>
  );
};

export default HeadsetOrientationChart;
