// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.duration {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}
.duration p {
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 40px;
  color: #000;
  margin: 0;
  margin-left: 20px;
}
.duration .progress-bar {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  gap: 10px;
}
.duration .progress-bar span {
  text-transform: uppercase;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/chapter/duration/duration.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,8BAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,SAAA;EACA,iBAAA;AACJ;AAEE;EACE,aAAA;EACA,8BAAA;EACA,8BAAA;EACA,SAAA;AAAJ;AAEI;EACE,yBAAA;EACA,eAAA;AAAN","sourcesContent":[".duration {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  justify-content: space-between;\n\n  p {\n    font-size: 18px;\n    padding: 5px 20px;\n    border-radius: 40px;\n    color: #000;\n    margin: 0;\n    margin-left: 20px;\n  }\n\n  .progress-bar {\n    display: flex;\n    flex-direction: row !important;\n    justify-content: space-between;\n    gap: 10px;\n\n    span {\n      text-transform: uppercase;\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
