import React from "react";
import "./chaptercompleted.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ChapterCompleted = ({ completedChapters }) => {

  return (
    <div className="chapter-completed">
      <div className="progress-container">
        <CircularProgressbar
          strokeWidth={17.5}
          text={`${completedChapters}%`}
          value={completedChapters}
          styles={buildStyles({
            textSize: "16px",
            pathColor: `#8C52FF`,
            textColor: "#012970",
            trailColor: "#545454",
            backgroundColor: "#545454",
            pathTransition: "stroke-dashoffset 0.5s ease 0s",
            trail: {
              strokeLinecap: "round",
            },
          })}
        />
      </div>
      <p>Chapters</p>
    </div>
  );
};

export default ChapterCompleted;
