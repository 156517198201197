import React, { useEffect, useState } from "react";
import "./cognitionanalysis.scss";
import AISuggestion from "../../components/cognition-analysis/AISuggestion";
import CognitionRadarChart from "../../components/cognition-analysis/charts/CognitionRadarChart";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import ControllerErgonomics from "../../components/cognition-analysis/ControllerErgonomics";
import HeadsetOrientation from "../../components/cognition-analysis/HeadsetOrientation";
import { useSelector } from "react-redux";

const CognitionAnalysis = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [radarData, setRadarData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [leftCheckboxes, setLeftCheckboxes] = useState([]);
  const [rightCheckboxes, setRightCheckboxes] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [error, setError] = useState("");
  const [courseNames, setCourseNames] = useState([]);

  useEffect(() => {
    const fetchCourseNames = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/course/names?collegeId=${currentUser.collegeId}`
        );
        setCourseNames(response.data);
        setLeftCheckboxes(new Array(response.data.length).fill(false));
      } catch (error) {
        console.error("Unable to fetch course names!", error.message);
      }
    };
    fetchCourseNames();
  }, [currentUser]);

  const handleCheckboxChange = (setCheckboxes, index) => {
    setCheckboxes((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleSubmit = async () => {
    const selectedCourses = courseNames
      .filter((_, index) => leftCheckboxes[index])
      .map((course) => course);
    const selectedCognitiveParams = [
      "eyeContact",
      "posture",
      "handGesture",
      "reactionTime",
      "movementDynamics",
    ].filter((_, index) => rightCheckboxes[index]);
    if (selectedCourses.length >= 1 && selectedCognitiveParams.length >= 3) {
      setIsModalOpen(false);
      setError("");
      try {
        const response = await axios.post(
          `${SERVER_URL}/user-analytics/average-cognitive-parameters/${currentUser.email}?collegeId=${currentUser.collegeId}`,
          {
            selectedCourses,
            cognitiveParameters: selectedCognitiveParams,
          }
        );
        setRadarData(response.data);
      } catch (error) {
        console.error("Unable to fetch cognitive averages!", error.message);
        setError("An error occurred while calculating the averages.");
      }
    } else {
      setError(
        "Please select at least one checkbox from the COURSES section and three checkboxes from the COGNITIVE PARAMETERS section."
      );
    }
  };

  const isSelectionValid = () => {
    const selectedLeft = leftCheckboxes.filter((checked) => checked).length;
    const selectedRight = rightCheckboxes.filter((checked) => checked).length;
    return selectedLeft >= 1 && selectedRight >= 3;
  };

  return (
    <div className="cognition-analysis-container">
      {isModalOpen ? (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-section">
              <div className="left-section">
                <h6>Courses</h6>
                {courseNames.map((course, index) => (
                  <label key={index}>
                    <input
                      type="checkbox"
                      checked={leftCheckboxes[index] || false}
                      onChange={() =>
                        handleCheckboxChange(setLeftCheckboxes, index)
                      }
                    />
                    {course}
                  </label>
                ))}
              </div>
              <div className="right-section">
                <h6>Cognitive Parameters</h6>
                <label>
                  <input
                    type="checkbox"
                    checked={rightCheckboxes[0]}
                    onChange={() => handleCheckboxChange(setRightCheckboxes, 0)}
                  />
                  Eye Contact
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={rightCheckboxes[1]}
                    onChange={() => handleCheckboxChange(setRightCheckboxes, 1)}
                  />
                  Posture
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={rightCheckboxes[2]}
                    onChange={() => handleCheckboxChange(setRightCheckboxes, 2)}
                  />
                  Hand Gesture
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={rightCheckboxes[3]}
                    onChange={() => handleCheckboxChange(setRightCheckboxes, 3)}
                  />
                  Reaction Time
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={rightCheckboxes[4]}
                    onChange={() => handleCheckboxChange(setRightCheckboxes, 4)}
                  />
                  Movement Dynamics
                </label>
              </div>
            </div>
            {error && <div className="error-message">{error}</div>}
            <button onClick={handleSubmit} className="submit-button">
              Submit
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="analysis-top">
            <div className="analysis-heading">
              <h1>
                <i className="bi bi-clipboard-data"></i>Cognition Analysis
              </h1>
              <span>Cognition Analysis</span>
            </div>
          </div>
          {isSelectionValid() ? (
            <div className="analysis-main">
              <div className="left-group">
                <AISuggestion />
                <CognitionRadarChart data={radarData} />
              </div>
              <div className="right-group">
                <ControllerErgonomics />
                <HeadsetOrientation />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default CognitionAnalysis;
