import React, { useEffect, useState } from "react";
import "./controllerergonomics.scss";
import controllerImage from "../../assests/controller.png";

const ControllerErgonomics = () => {
  const [shape1, setShape1] = useState(0);
  const [shape2, setShape2] = useState(0);
  const [shape3, setShape3] = useState(0);

  useEffect(() => {
    setShape1(3.6);
    setShape2(5.8);
    setShape3(8.3);
  }, []);

  return (
    <div className="controller-ergonomics-controller">
      <h6>Controller Ergonomics</h6>
      <div className="controller-details">
        <img src={controllerImage} alt="controller" />
        <div className="details-value">
          <div className="rectangle">
            <div className="shape1"></div>
            <div className="shape1-value">{shape1}</div>
          </div>
          <div className="rectangle">
            <div className="shape2"></div>
            <div className="shape2-value">{shape2}</div>
          </div>
          <div className="rectangle">
            <div className="shape3"></div>
            <div className="shape3-value">{shape3}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControllerErgonomics;
