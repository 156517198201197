import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import TotalCourses from "../../components/dashboard/TotalCourses";
import TotalChapters from "../../components/dashboard/TotalChapters";
import RemainingChapters from "../../components/dashboard/RemainingChapters";
import OverallProgress from "../../components/dashboard/OverallProgress";
import AssignmentProgress from "../../components/dashboard/AssignmentProgress";
import ChaptersCovered from "../../components/dashboard/ChaptersCovered";
import ProgressBars from "../../components/dashboard/ProgressBars";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [totalCourses, setTotalCourses] = useState(0);
  const [totalChapters, setTotalChapters] = useState(0);
  const [remainingChapters, setRemainingChapters] = useState(0);
  const [overallProgress, setOverallProgress] = useState(0);
  const [courseName, setCourseName] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const coursesChaptersResponse = await axios.get(
          `${SERVER_URL}/user-analytics/count-courses-chapters/${currentUser.email}?collegeId=${currentUser.collegeId}`
        );
        setTotalCourses(coursesChaptersResponse.data.totalCourses);
        setTotalChapters(coursesChaptersResponse.data.totalChapters);

        const remainingChaptersResponse = await axios.get(
          `${SERVER_URL}/user-analytics/${currentUser.email}/count-total-remaining-chapters?collegeId=${currentUser.collegeId}`
        );
        setRemainingChapters(
          remainingChaptersResponse.data.totalRemainingChapters
        );

        const overallProgressResponse = await axios.get(
          `${SERVER_URL}/user-analytics/total-progress/${currentUser.email}?collegeId=${currentUser.collegeId}`
        );
        setOverallProgress(overallProgressResponse.data.overallProgress);
        const courseResponse = await axios.get(
          `${SERVER_URL}/user-analytics/courses/${currentUser.email}?collegeId=${currentUser.collegeId}`
        );
        setCourseName(courseResponse.data.courses[0]);
      } catch (error) {
        console.error("Error fetching user analytics:", error.message);
      }
    };

    if (currentUser && currentUser.email) {
      fetchData();
    }
  }, [currentUser]);

  return (
    <div className="dashboard-container">
      <div className="dashboard-top">
        <TotalCourses total={totalCourses} />
        <TotalChapters totalChapters={totalChapters} />
        <RemainingChapters remainingChapters={remainingChapters} />
        <OverallProgress overallProgress={overallProgress} />
      </div>
      <div className="dashboard-bottom">
        <div className="component-wrapper">
          <ChaptersCovered courseName={courseName} />
        </div>
        <div className="component-wrapper">
          <AssignmentProgress />
        </div>
        <div className="component-wrapper progress-bars-wrapper">
          <ProgressBars />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
