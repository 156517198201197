import React from "react";
import "./overallprogress.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const OverallProgress = ({ overallProgress }) => {

  return (
    <div className="student-overall-progress">
      <h3>Overall Progress</h3>
      <div className="student-progress-container">
        <CircularProgressbar
          strokeWidth={17.5}
          text={`${overallProgress}%`}
          value={overallProgress}
          styles={buildStyles({
            textSize: "16px",
            pathColor: "#8c52ff",
            textColor: "#8c52ff",
            trailColor: "#d6d5d5",
            backgroundColor: "#FF5757",
            pathTransition: "stroke-dashoffset 0.5s ease 0s",
            pathShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
          })}
        />
      </div>
    </div>
  );
};

export default OverallProgress;
