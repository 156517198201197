// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-container {
  display: flex;
  height: 100%;
  position: relative;
}
.progress-container svg {
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));
}
.progress-container::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  filter: blur(6px);
  z-index: -1;
}
.progress-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/chapter/progress/progress.scss"],"names":[],"mappings":"AAAA;EACQ,aAAA;EACA,YAAA;EACA,kBAAA;AACR;AACQ;EACI,iDAAA;AACZ;AAEQ;EACI,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;AAAZ;AAGQ;EACI,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;AADZ","sourcesContent":[".progress-container {\n        display: flex;\n        height: 100%;\n        position: relative;\n\n        svg {\n            filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.3));\n        }\n\n        &::before {\n            content: \"\";\n            position: absolute;\n            top: 5px;\n            left: 5px;\n            width: 70px;\n            height: 70px;\n            border-radius: 50%;\n            filter: blur(6px);\n            z-index: -1;\n        }\n\n        &::after {\n            content: \"\";\n            position: absolute;\n            top: 0;\n            left: 0;\n            width: 100%;\n            height: 100%;\n            border-radius: 50%;\n            z-index: 0;\n        }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
