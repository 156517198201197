import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/header/Header";
import SideBar from "./components/sidebar/SideBar";
import Login from "./pages/login/Login";
import PrivateRoute from "./components/PrivateRoute";
import Main from "./components/main/Main";
import ChapterMain from "./components/main/ChapterMain";
import "./utils/browserEvents";
import CognitionAnalysisMain from "./components/main/CognitionAnalysisMain";

function HeaderWrapper() {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  return !isLoginPage && <Header />;
}

function App() {
  return (
    <BrowserRouter>
      <HeaderWrapper />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<SideBar />}>
            <Route path="dashboard" element={<Main />} />
            <Route
              path="cognition-analysis"
              element={<CognitionAnalysisMain />}
            />
            <Route
              path="dashboard/:courseName/:chapterName"
              element={<ChapterMain />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
