import React from "react";
import "./remainingchapters.scss";
import { FaBookOpenReader } from "react-icons/fa6";

const RemainingChapters = ({ remainingChapters }) => {
  return (
    <div className="remaining-chapters">
      <FaBookOpenReader className="icon" />
      <h3>Remaining Chapters</h3>
      <p>{`${remainingChapters}`}</p>
    </div>
  );
};

export default RemainingChapters;
