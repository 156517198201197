import React from 'react';
import "./aisuggesyion.scss";
import { GiArtificialIntelligence } from "react-icons/gi";

const AISuggestion = () => {
  return (
    <div className="ai-heading-container">
      <h1>
        <GiArtificialIntelligence className="icon" />
        skills suggested by ai
      </h1>
      <div className="left-group-top">
        <span>process designing</span>
        <span>leadership</span>
        <span>social enterprise</span>
        <span>supervision</span>
        <span>team management</span>
      </div>
    </div>
  );
}

export default AISuggestion