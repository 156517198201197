import React from "react";
import "./headsetorientation.scss";
import HeadsetOrientationChart from "./charts/HeadsetOrientationChart";

const HeadsetOrientation = () => {
  const data = [
    { position: "Front", value: 5 },
    { position: "Front Right", value: 4 },
    { position: "Back Right", value: 9},
    { position: "Back", value: 7 },
    { position: "Back Left", value: 8 },
    { position: "Front Left", value: 6 },
  ];

  return (
    <div className="headset-orientation-container">
      <h6>Headset Orientation</h6>
      <div className="chart-wrapper">
        <HeadsetOrientationChart data={data} />
      </div>
    </div>
  );
};

export default HeadsetOrientation;
