// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radar-chart-container {
  width: 100%;
  height: 500px;
}`, "",{"version":3,"sources":["webpack://./src/components/cognition-analysis/charts/cognitionradarchart.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;AACF","sourcesContent":[".radar-chart-container {\n  width: 100%;\n  height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
