import React from "react";
import "./learningpointscompleted.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const LearningPointsCompleted = ({ activityCompleted }) => {
  return (
    <div className="activity-completed">
      <div className="progress-container">
        <CircularProgressbar
          strokeWidth={17.5}
          text={`${activityCompleted}%`}
          value={activityCompleted}
          styles={buildStyles({
            textSize: "16px",
            pathColor: `#8C52FF`,
            textColor: "#012970",
            trailColor: "#545454",
            pathTransition: "stroke-dashoffset 0.5s ease 0s",
            trail: {
              strokeLinecap: "round",
            },
          })}
        />
      </div>
      <p>Learning Points</p>
    </div>
  );
};

export default LearningPointsCompleted;
