import React, { useEffect, useState } from "react";
import "./chapterscovered.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";
import BarChart from "./charts/BarChart";

const ChaptersCovered = ({ courseName }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/learning-points/completed/${currentUser.email}/${courseName}?collegeId=${currentUser.collegeId}`
        );
        setData(response.data);
      } catch (error) {
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };
    if (courseName !== "") {
      fetchData();
    }
  }, [currentUser, courseName]);

  return (
    <div className="course-covered">
      <h4>Chapters Covered</h4>
      <div className="chart-container">
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        <BarChart data={data} />
      </div>
    </div>
  );
};

export default ChaptersCovered;
