import React, { useState, useEffect, useRef, useCallback } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { FaArrowsAlt, FaCompress } from "react-icons/fa";

const WebGLLoaderChapter3 = ({ courseName, chapterName }) => {
  const [progress, setProgress] = useState(0);
  const [unityKey, setUnityKey] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const unityRef = useRef(null);

  const { unityProvider, loadingProgression, unload, error } = useUnityContext({
    loaderUrl: `https://webglstudent.s3.amazonaws.com/${courseName}/Chapter3/Chapter3.loader.js`,
    dataUrl: `https://webglstudent.s3.amazonaws.com/${courseName}/Chapter3/Chapter3.data`,
    frameworkUrl: `https://webglstudent.s3.amazonaws.com/${courseName}/Chapter3/Chapter3.framework.js`,
    codeUrl: `https://webglstudent.s3.amazonaws.com/${courseName}/Chapter3/Chapter3.wasm`,
  });

  useEffect(() => {
    setUnityKey(Date.now());
  }, [courseName, chapterName]);

  const handleUnload = useCallback(async () => {
    if (unload) {
      try {
        await unload();
        console.log("Unity content unloaded successfully");
      } catch (error) {
        console.error("Failed to unload Unity content:", error);
      }
    }
  }, [unload]);

  useEffect(() => {
    setIsLoading(true);
    setProgress(0);

    const updateProgress = () => {
      if (loadingProgression !== undefined) {
        try {
          setProgress(Math.floor(loadingProgression * 100));
          if (loadingProgression >= 1) {
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error updating progress:", error);
        }
      } else {
        console.error("Loading progression is undefined");
      }
    };

    updateProgress();

    return () => {
      handleUnload();
    };
  }, [chapterName, loadingProgression, handleUnload]);

  const toggleFullScreen = () => {
    if (unityRef.current) {
      if (!document.fullscreenElement) {
        unityRef.current.requestFullscreen().catch((err) => {
          console.error("Failed to enter full-screen mode:", err);
        });
        setIsFullScreen(true);
      } else {
        document.exitFullscreen().catch((err) => {
          console.error("Failed to exit full-screen mode:", err);
        });
        setIsFullScreen(false);
      }
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      console.log("Fullscreen changed:", document.fullscreenElement);
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (error) {
      console.error("Unity context error:", error);
    }
  }, [error]);

  return (
    <div style={{ position: "relative", width: "100%", height: "450px" }}>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "grey",
            fontSize: "20px",
          }}
        >
          <p>Loading... {progress}%</p>
        </div>
      )}
      <Unity
        key={unityKey}
        unityProvider={unityProvider}
        style={{
          width: "100%",
          height: "100%",
          display: isLoading ? "none" : "block",
        }}
        ref={unityRef}
      />
      <button
        onClick={toggleFullScreen}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          padding: "10px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          zIndex: 10,
        }}
      >
        {isFullScreen ? <FaCompress size={24} /> : <FaArrowsAlt size={24} />}
      </button>
    </div>
  );
};

export default WebGLLoaderChapter3;
