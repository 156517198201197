// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-heading-container {
  display: flex;
  flex-direction: column;
}
.ai-heading-container h1 {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
}
.ai-heading-container .left-group-top {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  height: max-content;
  padding: 10px 20px;
}
.ai-heading-container .left-group-top span {
  padding: 5px 15px;
  border: none;
  outline: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  background: #ccddf6;
  color: #737373;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/cognition-analysis/aisuggesyion.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,yBAAA;AACJ;AAEE;EACE,aAAA;EACA,SAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,0BAAA;EACA,mBAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAN","sourcesContent":[".ai-heading-container {\n  display: flex;\n  flex-direction: column;\n\n  h1 {\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    font-size: 26px;\n    font-weight: 700;\n    text-transform: uppercase;\n  }\n\n  .left-group-top {\n    display: flex;\n    gap: 20px;\n    justify-content: space-between;\n    height: max-content;\n    padding: 10px 20px;\n\n    span {\n      padding: 5px 15px;\n      border: none;\n      outline: none;\n      border-radius: 25px;\n      font-size: 16px;\n      font-weight: 500;\n      text-transform: capitalize;\n      background: #ccddf6;\n      color: #737373;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
