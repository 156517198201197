// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main {
  margin-top: 120px;
  padding: 20px 30px 0px 30px;
}
@media (max-width: 1199px) {
  #main {
    padding: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/main/main.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,2BAAA;AACJ;AACI;EAJJ;IAKQ,aAAA;EAEN;AACF","sourcesContent":["#main {\n    margin-top: 120px;\n    padding: 20px 30px 0px 30px;\n\n    @media (max-width: 1199px) {\n        padding: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
