// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assignment-progress {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1;
  height: 400px;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.assignment-progress::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(90deg, #642bff, #ff22e6);
  border-radius: 22px;
  z-index: -1;
}
.assignment-progress::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  border-radius: 20px;
  z-index: -1;
}
.assignment-progress h4 {
  text-transform: uppercase;
}
.assignment-progress .chart-container {
  width: 100%;
  height: 100%;
  align-self: center;
}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/assignmentprogress.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,SAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,4CAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,oDAAA;EACA,mBAAA;EACA,WAAA;AACJ;AAEE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAGE;EACE,yBAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAFJ","sourcesContent":[".assignment-progress {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  flex: 1;\n  height: 400px;\n  padding: 20px;\n  background: #fff;\n  border-radius: 20px;\n  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n\n  &::before {\n    content: \"\";\n    position: absolute;\n    top: -2px;\n    left: -2px;\n    right: -2px;\n    bottom: -2px;\n    background: linear-gradient(90deg, #642bff, #ff22e6);\n    border-radius: 22px;\n    z-index: -1;\n  }\n\n  &::after {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: white;\n    border-radius: 20px;\n    z-index: -1;\n  }\n\n  h4 {\n    text-transform: uppercase;\n  }\n\n  .chart-container {\n    width: 100%;\n    height: 100%;\n    align-self: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
