import React, { useEffect, useState } from "react";
import "./assignmentprogress.scss";
import { SERVER_URL } from "../../utils/helper";
import axios from "axios";
import { useSelector } from "react-redux";
import AssignmentBarChart from "./charts/AssignmentBarChart";

const AssignmentProgress = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(
          `${SERVER_URL}/user-analytics/assignment-percentages/${currentUser.email}?collegeId=${currentUser.collegeId}`
        );
        const transformedData =
          response.data[0]?.chapters.map((chapter) => ({
            chapter: chapter.chapter,
            assignmentPercentage: chapter.assignmentPercentage || 0,
          })) || [];
        setData(transformedData);
      } catch (error) {
        setError("Error loading data!");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser]);

  return (
    <div className="assignment-progress">
      <h4>Assignment Progress</h4>
      <div className="chart-container">
        {loading && <p>Loading...</p>}
        {error && <p className="error">{error}</p>}
        <AssignmentBarChart data={data} />
      </div>
    </div>
  );
};

export default AssignmentProgress;
