import React from 'react';
import './progress.scss';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Progress = ({duration}) => {

  return (
    <div className="progress-container">
      <CircularProgressbar
        strokeWidth={17.5}
        value={duration}
        styles={buildStyles({
          textSize: "16px",
          pathColor: `#8c52ff`,
          textColor: "#012970",
          trailColor: "#545454",
          backgroundColor: "#3e98c7",
          pathTransition: "stroke-dashoffset 0.5s ease 0s",
          trail: {
            strokeLinecap: "round",
          },
        })}
      />
    </div>
  );
}

export default Progress
