import React from "react";
import "./assignmentcompleted.scss";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const AssignmentCompleted = ({ assignmentCompleted }) => {
  return (
    <div className="assignment-completed">
      <div className="progress-container">
        <CircularProgressbar
          strokeWidth={17.5}
          text={`${assignmentCompleted}%`}
          value={assignmentCompleted}
          styles={buildStyles({
            textSize: "16px",
            pathColor: `#8C52FF`,
            textColor: "#012970",
            trailColor: "#545454",
            pathTransition: "stroke-dashoffset 0.5s ease 0s",
            trail: {
              strokeLinecap: "round",
            },
          })}
        />
      </div>
      <p>Assignments</p>
    </div>
  );
};

export default AssignmentCompleted;
