import React from "react";
import {
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ payload, label, active }) => {
  if (active && payload && payload.length) {
    const { value } = payload[0];
    return (
      <div
        style={{
          backgroundColor: "#000",
          color:"#fff",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          borderRadius: "20px",
          border: "1px solid #ccc",
          padding: "15px",
        }}
      >
        <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
        <p style={{ margin: 0 }}>{`Learning Points: ${value}`}</p>
      </div>
    );
  }
  return null;
};

const CustomLegend = () => {
  return null;
};

const BarChartComponent = ({ data }) => {
  if (!data || data.length === 0) {
    return <p>No data available</p>;
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RechartsBarChart
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="chapter"
          tickMargin={10}
          label={{
            value: "Chapter",
            position: "insideBottom",
            offset: -30,
            textAnchor: "middle",
          }}
        />
        <YAxis
          label={{
            value: "Learning Points",
            angle: -90,
            position: "insideLeft",
            offset: 0,
            style: {
              textAnchor: "middle",
            },
          }}
          domain={[0, 5]}
          ticks={[1, 2, 3, 4, 5]}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend content={<CustomLegend />} />
        <Bar
          dataKey="learningPoints"
          fill="#a9cafd"
          stroke="#4a4a4a"
          strokeWidth={1}
          shape={<CustomBar />}
        />
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

const CustomBar = (props) => {
  const { fill, x, y, width, height } = props;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        style={{
          filter: "drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5))",
        }}
      />
      <polygon
        points={`${x},${y} ${x + width},${y} ${x + width - 8},${y - 8} ${
          x + 8
        },${y - 8}`}
        fill={fill}
        fillOpacity="0.7"
      />
      <polygon
        points={`${x + width},${y} ${x + width - 8},${y - 8} ${x + width - 8},${
          y + height - 8
        } ${x + width},${y + height}`}
        fill={fill}
        fillOpacity="0.5"
      />
    </g>
  );
};

export default BarChartComponent;
