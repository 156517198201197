import React from "react";
import "./heading.scss";

const Heading = ({ courseName, chapterName }) => {
  return (
    <div className="heading">
      <h1>{courseName}</h1>
      <hr className="divider" />
      <h5>{chapterName}</h5>
    </div>
  );
};

export default Heading;
