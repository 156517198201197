import React, { useState } from "react";
import "./login.scss";
import PasswordInput from "../../components/password-input/PasswordInput";
import EmailInput from "../../components/email-input/EmailInput";
import { FaUserCircle } from "react-icons/fa";
import logo from "../../assests/valtep-logo.png";
import Cookies from "js-cookie";
import axios from "axios";
import { SERVER_URL } from "../../utils/helper";
import { useDispatch } from "react-redux";
import {
  signInStart,
  signInSuccess,
  signInFailure,
} from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ otp: "", email: "" });
  const [otpSent, setOtpSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSendOTP = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${SERVER_URL}/otp/send-otp`, {
        email: formData.email,
      });
      if (
        response.status === 200 &&
        response.data.message === "OTP sent successfully"
      ) {
        setLoading(false);
        setError(null);
        setOtpSent(true);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${SERVER_URL}/otp/verify-otp`, {
        otp: formData.otp,
        email: formData.email,
      });

      const data = response.data;
      dispatch(signInStart());
      if (response.status === 200) {
        dispatch(signInSuccess(data));
        Cookies.set("access_token", data._id);
        setError(null);
        setLoading(false);
        navigate("/dashboard");
      } else {
        dispatch(signInFailure(data.message));
        throw new Error(response.data.message || "OTP verification failed");
      }
    } catch (error) {
      setError(error.response?.data?.message || error.message);
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <img src={logo} alt="" />
        <form method="POST" onSubmit={handleSubmit}>
          <FaUserCircle className="icon" />
          <EmailInput
            name="email"
            id="email"
            type="email"
            placeholder="Email"
            autoComplete="on"
            value={formData.email}
            onChange={handleChange}
          />
          {!otpSent && (
            <button
              type="button"
              className="btn-send-otp"
              onClick={handleSendOTP}
            >
              {loading ? "Sending OTP..." : "Send OTP"}
            </button>
          )}
          {otpSent && (
            <>
              <PasswordInput
                name="otp"
                id="otp"
                placeholder="OTP"
                autoComplete="on"
                value={formData.otp}
                onChange={handleChange}
              />
              <button className="btn-login" type="submit">
                {loading ? "Logging in..." : "Login"}
              </button>
            </>
          )}
          {error && <p style={{ color: "#ff0000" }}>{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default Login;
