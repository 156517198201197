import React from 'react';
import "./main.scss";
import Chapter from '../../pages/chapter/Chapter';
import { useParams } from 'react-router-dom';

const ChapterMain = () => {
  const { courseName } = useParams();
  const { chapterName } = useParams();

  return (
    <main id="main" className="main">
      <Chapter courseName={courseName} chapterName={chapterName} />
    </main>
  );
}

export default ChapterMain
