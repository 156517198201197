// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meter {
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
}
.meter .chart {
  display: flex;
  flex-direction: column;
  height: 100px;
  flex: 1 1;
  position: relative;
}
.meter .chart .recharts-pie {
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.5));
}
.meter .chart .recharts-pie-path {
  stroke-width: 2px;
  stroke: rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
}
.meter .chart::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
}
.meter .info {
  flex: 1 1;
}
.meter .info ul {
  margin-top: 100px;
  font-size: 12px;
}
.meter .info ul li p.stu {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/chapter/meter/meter.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,aAAA;EACA,SAAA;EACA,kBAAA;AACJ;AACI;EACE,mDAAA;AACN;AACI;EACE,iBAAA;EACA,gCAAA;EACA,mDAAA;AACN;AACI;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;AACN;AAGE;EACE,SAAA;AADJ;AAGI;EACE,iBAAA;EACA,eAAA;AADN;AAIQ;EACE,gBAAA;AAFV","sourcesContent":[".meter {\n  display: flex;\n  justify-content: space-between;\n  height: 100px;\n  align-items: center;\n\n  .chart {\n    display: flex;\n    flex-direction: column;\n    height: 100px;\n    flex: 1;\n    position: relative;\n\n    .recharts-pie {\n      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.5));\n    }\n    .recharts-pie-path {\n      stroke-width: 2px;\n      stroke: rgba(255, 255, 255, 0.5);\n      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));\n    }\n    &::before {\n      content: \"\";\n      position: absolute;\n      top: 0;\n      left: 0;\n      width: 100%;\n      height: 100%;\n      border-radius: 50%;\n      z-index: -1;\n    }\n  }\n\n  .info {\n    flex: 1;\n\n    ul {\n      margin-top: 100px;\n      font-size: 12px;\n\n      li {\n        p.stu {\n          font-weight: 700;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
